import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { injectIntl } from 'gatsby-plugin-react-intl';

import mq, { mqdown } from '@mq';
import LogoSvg from '@icons/logo.svg';
import Seo from '@core/Seo';
import Sections from '@components/Sections';
import Button from '@core/Button';
import Image from '@core/Image';
import { cover } from '@mixins';

const CateringPage = ({
  data: {
    contentfulCateringPage: {
      heroTitle,
      heroSubtitle,
      heroCallToAction,
      heroCallToActionUrl,
      heroImage,
      heroImageMobile,
      sections,
    },
  },
}) => (
  <>
    <Seo
      pageTitle={heroTitle}
      pageDescription="Design your own menu or pick a lunch set and try out the best of what Denizen Kitchen has to offer."
    />
    <main>
      <Hero>
        <HeroImage image={heroImage} />
        <HeroImageMobile image={heroImageMobile} />
        <HeroText>
          <Logo />
          <HeroTitle>{heroTitle}</HeroTitle>
          <HeroSubtitle>{heroSubtitle}</HeroSubtitle>
          <HeroButton to={heroCallToActionUrl}>{heroCallToAction}</HeroButton>
        </HeroText>
      </Hero>

      <Sections sections={sections} pageTitle="catering" />
    </main>
  </>
);

const Hero = styled.div`
  margin-top: calc(-1 * var(--navigation-height));
  position: relative;

  ${mq.small} {
    margin-top: var(--space-s);
  }
`;

const HeroImage = styled(Image)`
  ${mqdown.small} {
    display: none;
  }
`;

const HeroImageMobile = styled(Image)`
  ${mq.small} {
    display: none;
  }
`;

const HeroText = styled.div`
  ${cover};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${mqdown.small} {
    margin-top: var(--space-xs);
  }
`;

const Logo = styled(LogoSvg)`
  width: 6.875rem;
  height: auto;

  ${mq.small} {
    width: 14rem;
  }

  ${mq.medium} {
    width: 19.75rem;
  }
`;

const HeroTitle = styled.h1`
  margin-top: 0.5rem;

  ${mq.small} {
    margin-top: 1rem;
  }
`;

const HeroSubtitle = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  ${mq.small} {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }
`;

const HeroButton = styled(Button)`
  --content-color: var(--color-secondary);
  --background-color: var(--color-text);
`;

export const CateringPageQuery = graphql`
  query CateringPageQuery($locale: String) {
    contentfulCateringPage(node_locale: { eq: $locale }) {
      node_locale
      heroTitle
      heroSubtitle
      heroCallToAction
      heroCallToActionUrl
      heroImage {
        description
        id
        gatsbyImageData(
          placeholder: DOMINANT_COLOR
          breakpoints: [375, 560, 750, 1080, 1366, 1920, 2560]
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
          quality: 75
        )
      }
      heroImageMobile {
        description
        id
        gatsbyImageData(
          placeholder: DOMINANT_COLOR
          breakpoints: [375, 560, 750]
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
          quality: 75
        )
      }
      sections {
        ... on ContentfulEntry {
          ...sections
        }
      }
    }
  }
`;

export default injectIntl(CateringPage);
